import React from 'react'
import Img from '../../Img'
import Button from '../../forms/Button'
import SearchIconThin from '../../icons/SearchIconThin'
import { generateUrl } from '../../../utils/Helper'

type Props = {
    lang: any
    langCode: string
}

const AskDellaSection = ({ lang, langCode }: Props) => {
    return (
        <div className='w-full relative pt-0'>
            <div className='relative hidden lg:flex' style={{ height: 280 }}>
                <Img src={`https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/banner-under-pop-apartment.jpg`} className='lg:flex hidden object-right object-cover rounded-xl' style={{
                    height: 280,
                    // objectPosition: '0 30%'
                }} />
            </div>
            <div className='relative w-full flex lg:hidden' style={{ paddingTop: '44%' }}>
                <Img src={`https://d2jgpj59n9tcev.cloudfront.net/assets/homepage/entrust_apartmen_mobile.png`} className='flex lg:hidden object-center object-cover w-full rounded-xl' />
            </div>
            <div className='w-2/3 z-1 lg:w-3/4 px-3 lg:px-16 absolute top-0 bottom-0 left-auto'>
                <div className='justify-center lg:-mt-6 mt-0 flex flex-col items-start min-h-full'>
                    <p className='mb-14px lg:mb-6 text-white font-semibold text-left'>
                        <span className='text- lg:text-38px leading-tight'>{lang.homepage_ask_della_subtitle}</span>
                    </p>
                    <a href={generateUrl(langCode, `/della?from=${process.env.NEXT_PUBLIC_MAINSITE_URL}`)} target='_blank'>
                        <div className='flex flex-row bg-white text-black-48 items-center rounded-full py-2 px-3 lg:py-3 lg:px-5'>

                            <img src='/static/images/svg/question_icon.svg' className='mr-3' alt='icon question' style={{ width: 24, height: 24 }} />

                            <span className='text-13px lg:text-xl font-semibold whitespace-no-wrap'>
                                {lang.homepage_ask_della_button}
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AskDellaSection